import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/RMNklq_X0J8">
    <SEO title="The Wells of God - Jesus in Genesis" />
  </Layout>
)

export default SermonPost
